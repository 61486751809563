import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'
import { gql, useQuery } from '@apollo/client'

const RAISED_ICP = 83506
const RAISED_USD = 613877

const CONFIG_QUERY = gql`
  query {
    config {
      raisedBtc
      raisedBtcUsd
      raisedIcp
      raisedIcpUsd
      raisedEth
      raisedEthUsd
    }
  }
`

export default function Hero() {
  const { data, error } = useQuery(CONFIG_QUERY)

  return (
    <section className='w-full'>
      <div className='mx-auto flex w-full max-w-5xl flex-col py-16 pt-60 sm:pt-44'>
        <h1 className='homeHero'>
          Crowdfund the <em>future</em>
          <br />
        </h1>
        <button
          type='button'
          className='my-10 mx-auto inline-flex w-1/2 items-center rounded-full border border-transparent bg-gradient-to-br from-blue-500 to-blue-600  px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-1/3'
        >
          <div className='mx-auto flex'>
            <p className='text- text-center sm:text-xl'>
              <Link to='/projects/new'>Raise in cryptocurrency</Link>
            </p>
          </div>
        </button>
        <div className='bg-clear mt-4 pb-4'>
          <div className='relative'>
            <div className='bg-clear absolute inset-0 h-1/2' />
            <div className='relative mx-auto max-w-7xl px-8 sm:px-6 lg:px-8'>
              <div className='mx-auto max-w-4xl'>
                <dt className='font-regular order-2  mt-2 text-center leading-6 text-neutral-500'>
                  disbursed to project creators
                </dt>
                <dl className='space-y-4 rounded-lg sm:grid sm:grid-cols-3'>
                  <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:mt-4 sm:border-0 sm:border-r'>
                    <dt className='text-md font-regular  order-2 mt-2 leading-6 text-neutral-500'>
                      <CountUp
                        end={data?.config?.raisedBtcUsd}
                        prefix='$ '
                        suffix=' USD'
                      />
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <img src='/btc.svg' className='mr-2 inline-block h-6' />
                      <CountUp end={data?.config?.raisedBtc} suffix=' BTC' />
                    </dd>
                  </div>
                  <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>
                    <dt className='text-md font-regular order-2 mt-2 leading-6 text-neutral-500'>
                      <CountUp
                        end={data?.config?.raisedIcpUsd}
                        prefix='$ '
                        suffix=' USD'
                      />
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <img src='/icp.svg' className='mr-2 inline-block h-4' />
                      <span>
                        <CountUp end={data?.config?.raisedIcp} suffix=' ICP' />
                      </span>
                    </dd>
                  </div>
                  <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                    <dt className='text-md font-regular order-2 mt-2 leading-6 text-neutral-500'>
                      <CountUp
                        end={data?.config?.raisedEthUsd}
                        prefix='$ '
                        suffix=' USD'
                      />
                    </dt>
                    <dd className='order-1 flex items-center justify-center text-4xl font-bold tracking-tight text-blue-600'>
                      <img src='/eth.svg' className='mr-2 inline-block h-6' />
                      <CountUp end={data?.config?.raisedEth} suffix=' ETH' />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
