import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const CookiesBanner = () => {
  const [hasSeenCookies, setHasSeenCookies] = useState(
    JSON.parse(localStorage.getItem('acceptedCookies'))
  )

  const handleCookiesBanner = () => {
    localStorage.setItem('acceptedCookies', true)
    setHasSeenCookies(true)
  }

	return (
		<div
			className={classNames(
				'fixed bottom-0 left-0 w-full p-4 text-center bg-gray-800 text-gray-400 shadow backdrop-filter backdrop-blur-lg bg-opacity-70',
				{
					hidden: hasSeenCookies,
				}
			)}
		>
      <div className='block sm:flex justify-center items-center'>
        <div className="mr-12 text-sm">
          By using this site, you agree to our use of cookies, which we use to
          analyse our traffic in accordance with our
          <Link to='/pages/privacy'>
            <span className='ml-2 text-white hover:underline'>Privacy Policy</span>
          </Link>
          .
        </div>
        <button
          type="button"
          className="px-4 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 mt-2 sm:mt-0"
          onClick={handleCookiesBanner}
        >
          Accept
        </button>
      </div>
		</div>
	)
}

export default CookiesBanner
