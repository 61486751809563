import React from 'react'
import { createContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

const INITIAL_STATE = {
  principalID: null,
  setPrincipalID: () => {},
  nftID: null,
  setNftID: () => {},
  step: 1,
  setStep: () => {},
  previousStep: () => {},
}

const CONFIG_QUERY = gql`
  query {
    config {
      nftCanisterPrincipal
      nftSmartContractAddress
      ethChain
    }
  }
`

export const NftRewardFormContext = createContext(INITIAL_STATE)

export function NftRewardFormProvider({ children }) {
  const [step, setStep] = useState(INITIAL_STATE.step)
  const [principalID, setPrincipalID] = useState(INITIAL_STATE.principalID)
  const [nftID, setNftID] = useState(INITIAL_STATE.nftID)
  const { data: configData } = useQuery(CONFIG_QUERY)

  const previousStep = () => {
    if (step === 1) return

    setStep(step - 1)
  }

  const value = {
    principalID,
    setPrincipalID,
    nftID,
    setNftID,
    step,
    setStep,
    previousStep,
    configData,
  }

  return (
    <NftRewardFormContext.Provider value={value}>
      {children}
    </NftRewardFormContext.Provider>
  )
}
