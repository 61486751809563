import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { Principal } from '@dfinity/principal'
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/solid'
import { gql, useLazyQuery } from '@apollo/client'
import useNftRewardForm from '../../hooks/useNftRewardForm'
import { wallets } from '../../helpers/wallets'

const PRINCIPAL_TOKEN_QUERY = gql`
  query GetPrincipalToken($principal: String!, $nftCanister: String!) {
    principalTokens(principal: $principal, nftCanister: $nftCanister)
  }
`

const ConnectWalletForm = () => {
  const { setStep, nftID, setNftID, principalID, setPrincipalID, configData } =
    useNftRewardForm()

  const [getPrincipalToken, { data }] = useLazyQuery(PRINCIPAL_TOKEN_QUERY)

  const handleWalletConnect = useCallback(
    (provider) => {
      wallets[provider]
        .connect([configData?.config?.nftCanisterPrincipal])
        .then(setPrincipalID)
    },
    [wallets, configData],
  )

  useEffect(() => {
    if (!data) return

    if (data.principalTokens.length > 0) {
      setNftID(data?.principalTokens[0])
    }
  }, [data, setNftID])

  const checkNftOwnership = useCallback(async () => {
    if (principalID) {
      await getPrincipalToken({
        variables: {
          principal: principalID,
          nftCanister: configData?.config?.nftCanisterPrincipal,
        },
      })
    }
  }, [getPrincipalToken, principalID, configData])

  const renderTokenPresence = () => {
    if (!data?.principalTokens) return

    if ((data?.principalTokens || []).length > 0) {
      return (
        <>
          <CheckCircleIcon className='mr-1 h-7 w-7 text-green-500' />
          <p className='text-sm font-medium'>You are a Crowdfund NFT holder</p>
        </>
      )
    }

    return (
      <>
        <XCircleIcon className='mr-1 h-7 w-7 text-red-500' />
        <p className='text-sm font-medium'>
          You are not a Crowdfund NFT holder
        </p>
      </>
    )
  }

  return (
    <div className='bg-clear flex w-full flex-col space-y-2 px-8 md:p-0'>
      <div className='w-full space-y-1 md:grid-cols-2 md:flex-row lg:flex'>
        <div className='bg-clear flex flex-col px-4 py-4 md:w-1/2 lg:w-1/3'>
          <p className='pt-16 font-sans text-2xl font-bold tracking-tight text-neutral-900 md:px-12 md:text-4xl'>
            Connect your IC wallet
          </p>
          <p className='text-md font-sans font-light text-neutral-500 md:px-12 md:pt-4'>
            To verify that you are a CrowdFund NFT holder
          </p>
        </div>
        <div className='border-1 flex-1 flex-col rounded-l-3xl rounded-r-3xl border border-white bg-white bg-opacity-50 p-8 bg-blend-saturation md:rounded-r-none md:rounded-l-3xl md:px-36 md:py-24'>
          <div className='flex w-full flex-col items-center justify-center'>
            {Object.keys(wallets).map((w) => (
              <button
                key={w}
                type='button'
                className='mt-5 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-500 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 sm:w-1/2'
                onClick={() => handleWalletConnect(w)}
              >
                <>
                  <img src={`/${w}.png`} className='mr-2 h-7' />
                  <span>{`Connect with ${
                    w[0].toUpperCase() + w.slice(1)
                  } Wallet`}</span>
                </>
              </button>
            ))}
          </div>
          <hr className='my-12' />

          <div className='flex w-full flex-col items-center justify-center'>
            <button
              type='button'
              onClick={checkNftOwnership}
              className={classNames(
                'flex w-full items-center justify-center rounded-md border border-transparent bg-slate-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 sm:w-1/2',
                principalID === '' && 'pointer-events-none bg-slate-500',
              )}
            >
              <span>Verify CrowdFund NFT Holding</span>
            </button>

            {data?.principalTokens.length > 1 && (
              <div className='mt-5 flex w-full items-center justify-center'>
                <select
                  onChange={(event) => {
                    setNftID(parseInt(event.target.value))
                  }}
                  className={`
                   block w-full rounded-xl border bg-white py-3 px-3 text-gray-700
                   focus:outline-none sm:w-1/2 sm:text-sm
                 `}
                >
                  {data?.principalTokens.map((nft) => (
                    <option key={nft} value={nft}>
                      CrowdFund NFT #{nft}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className='mt-5 flex items-center'>
              {renderTokenPresence()}
            </div>
          </div>

          {nftID !== null && (
            <div className='mt-5 flex w-full justify-center md:justify-end'>
              <button
                onClick={() => setStep(2)}
                className='flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 sm:w-1/2 md:w-auto md:justify-start'
              >
                <span>Next step</span>
                <ArrowRightIcon className='ml-2 h-5 w-5' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConnectWalletForm
