import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Container as ModalContainer } from 'react-modal-promise'
import App from './screens/app'
import UserProvider from './providers/userProvider'
import SessionProvider from './providers/sessionProvider'

const renderApp = () => {
  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <SessionProvider>
      <BrowserRouter>
        <div id='modal' />
        <ModalContainer />
        <UserProvider>
          <App />
        </UserProvider>
      </BrowserRouter>
    </SessionProvider>,
  )
}

document.addEventListener('turbo:load', () => {
  renderApp()
})
