import React from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

const PROJECTS_QUERY = gql`
  query Projects {
    featuredProjects {
      project {
        urlSlug
        title
        featuredImage
        totalGoal
        fundingType
      }
    }
  }
`

export default function Featured() {
  const { data: featuredProjectData } = useQuery(PROJECTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { states: ['live'] },
  })

  const featured = featuredProjectData?.featuredProjects

  return (
    <div className='bg-white'>
      <div className='mx-8 max-w-5xl py-10 sm:mx-auto sm:py-8 '>
        <div className='sm:flex sm:items-baseline sm:justify-between'>
          <h2 className='text-4xl font-bold tracking-tight text-gray-900'>
            Featured projects
          </h2>
          <Link to='/projects'>
            <a className='hidden text-sm font-semibold text-blue-600 hover:text-blue-500 sm:block'>
              View all projects
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </Link>
        </div>

        <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8'>
          <div className='aspect-w-2 aspect-h-1 sm:aspect-h-1 sm:aspect-w-1 group overflow-hidden rounded-lg sm:row-span-2'>
            <img
              src={featured?.[0]?.project?.featuredImage}
              className='object-cover object-center group-hover:opacity-75'
            />
            <div
              aria-hidden='true'
              className='bg-gradient-to-b from-transparent to-black opacity-75'
            />
            <div className='flex items-end p-6'>
              <div>
                <h3 className='text-xl font-semibold text-white'>
                  <Link to={`/projects/${featured?.[0]?.project?.urlSlug}`}>
                    <span className='absolute inset-0' />
                    {featured?.[0]?.project?.title}
                  </Link>
                </h3>
                <p
                  aria-hidden='true'
                  className='mt-1 text-sm font-semibold text-blue-300'
                >
                  Raising {featured?.[0]?.project?.totalGoal}{' '}
                  {featured?.[0]?.project?.fundingType?.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
          <div className='aspect-w-2 aspect-h-1 group overflow-hidden rounded-lg sm:relative'>
            <img
              src={featured?.[1]?.project?.featuredImage}
              className='object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full'
            />
            <div
              aria-hidden='true'
              className='bg-gradient-to-b from-transparent to-black opacity-75 sm:absolute sm:inset-0'
            />
            <div className='flex items-end p-6 sm:absolute sm:inset-0'>
              <div>
                <h3 className='text-xl font-semibold text-white'>
                  <Link to={`/projects/${featured?.[1]?.project?.urlSlug}`}>
                    <span className='absolute inset-0' />
                    {featured?.[1]?.project?.title}
                  </Link>
                </h3>
                <p aria-hidden='true' className='mt-1 text-sm text-blue-300'>
                  Raising {featured?.[1]?.project?.totalGoal}{' '}
                  {featured?.[1]?.project?.fundingType?.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
          <div className='aspect-w-2 aspect-h-1 group overflow-hidden rounded-lg sm:relative'>
            <img
              src={featured?.[2]?.project?.featuredImage}
              className='object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full'
            />
            <div
              aria-hidden='true'
              className='bg-gradient-to-b from-transparent to-black opacity-75 sm:absolute sm:inset-0'
            />
            <div className='flex items-end p-6 sm:absolute sm:inset-0'>
              <div>
                <h3 className='text-xl font-semibold text-white'>
                  <Link to={`/projects/${featured?.[2]?.project?.urlSlug}`}>
                    <span className='absolute inset-0' />
                    {featured?.[2]?.project?.title}
                  </Link>
                </h3>
                <p
                  aria-hidden='true'
                  className='mt-1 text-sm font-semibold text-blue-300'
                >
                  Raising {featured?.[2]?.project?.totalGoal}{' '}
                  {featured?.[2]?.project?.fundingType?.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-6 sm:hidden'>
          <Link to='/projects'>
            <div className='block text-sm font-semibold text-blue-600 hover:text-blue-500'>
              View all projects
              <span aria-hidden='true'> &rarr;</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
