import React from 'react'

const AuthorProfile = ({ author }) => {
  const { firstName, lastName, bio, profilePicture, role } = author

  return (
    <div className='mt-10 flex w-full flex-col  items-start rounded-2xl px-2'>
      <figure className='h-20 w-20 overflow-hidden rounded-full bg-gray-300'>
        {profilePicture && <img src={profilePicture} />}
      </figure>
      <div className='mt-4 flex w-full flex-col items-start'>
        <p className='text-lg font-semibold text-black'>
          {firstName} {lastName}
        </p>
        <p className='text-sm font-semibold text-gray-500'>{role}</p>
        <div
          className='whitespace-pre-wrap text-xs text-gray-500'
          dangerouslySetInnerHTML={{ __html: bio }}
        />
      </div>
    </div>
  )
}

export default AuthorProfile
