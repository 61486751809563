import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './menu'
import NFTs from './nfts'

export default function NFTCollection({ project }) {
  return (
    <div className='w-full'>
      <div className='mx-auto flex w-full max-w-7xl flex-col space-y-4 px-4 py-4 md:flex-row md:space-y-0 md:space-x-4'>
        <div className='flex w-full flex-col space-y-4 md:w-3/12'>
          <Menu />
        </div>

        <div
          id='nft-collection-rewards'
          className='flex w-full flex-col space-y-8 md:w-9/12'
        >
          <NFTs exampleNfts={project.exampleNfts} />
          <div className='flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'>
            <p className='text-2xl font-bold text-black'>Rewards</p>

            <div
              className='font-regular my-4 whitespace-pre-wrap text-neutral-600'
              dangerouslySetInnerHTML={{ __html: project.rewards }}
            />
          </div>
          <div
            id='nft-collection-how-it-works'
            className='flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'
          >
            <p className='text-2xl font-bold text-black'>How It Works</p>

            <div className='flex flex-row space-y-4  font-light text-neutral-600'>
              <div className='flex w-1/2 flex-col'>
                <p className='mt-6'>
                  When you back a project, you transfer tokens into an escrow
                  wallet controlled by a fully automated network of smart
                  contracts.
                </p>
                <p className='mt-6'>
                  The reason for this is that projects do not receive any funds
                  until they meet their funding goal.
                </p>
                <p className='mt-6'>
                  If a project does not meet its goal in the specified
                  timeframe, project backers are reimbursed - minus the
                  transaction fee.
                </p>
                <p className='mt-6'>
                  If a project does meet its funding goal then funds are
                  released from the Funded escrow wallet into the project
                  creator's wallet. Similarly, NFTs are delivered to project
                  backers wallets.
                </p>
              </div>
              <div className='flex w-1/2'>
                <img src='/how-it-works.png' className='' />
              </div>
            </div>
          </div>
          <div
            id='nft-collection-resale-and-secondary-market'
            className='mb-5 flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'
          >
            <p className='text-2xl font-bold text-black'>
              Resale &amp; Secondary Markets
            </p>

            <div className='mt-4 flex flex-col space-y-4 font-light text-neutral-600'>
              <p>
                As a backer who receives an NFT through Funded's crowdfunding
                platform, you have the right to sell your NFT on secondary
                marketplaces. Once the NFTs are listed on a secondary
                marketplace, the contractual agreement between backers and
                creators is transferred to the new NFT owners.
              </p>
              <p>
                Please note that the legal obligation to fulfill rewards lies
                with the creators of the project and their agreement is with the
                NFT holders. This means that creators are required to provide
                rewards to secondary NFT owners.
              </p>
              <p>
                Funded charges a royalty fee for selling NFTs minted by Funded
                on secondary marketplaces. Please refer to our{' '}
                <Link to='/pages/fees'>
                  <span className='font-bold text-blue-500'>fees</span>
                </Link>{' '}
                for me information.
              </p>
              <Link to='/pages/terms'>
                <div
                  className={`
                    w-full cursor-pointer rounded-lg bg-blue-600 px-4 py-3
                    text-center text-sm text-white hover:bg-blue-700 focus:outline-none
                `}
                >
                  Read more about us in our T&amp;C's
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
