import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import Input from '../forms/input'
import useProjectForm from '../../hooks/useProjectForm'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const stepThreeSchema = Yup.object().shape({
  targetAmount: Yup.number()
    .positive('Target raise must be greater than zero')
    .required('Enter a raise amount'),
  useTiers: Yup.boolean(),
  walletId: Yup.string(),
})

const initialValues = {
  fundingType: 'icp',
  targetAmount: '',
  nftVolume: 0,
  walletId: '',
  useTiers: true,
}

const fundingTypes = [
  { label: 'ICP', value: 'icp' },
  { label: 'BTC', value: 'btc' },
  { label: 'ckBTC', value: 'ckbtc' },
  { label: 'ETH', value: 'eth' },
]

const FundingTypeForm = () => {
  const { setStep, setProject, previousStep } = useProjectForm()
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (form) => {
    try {
      setLoading(true)

      setProject((project) => ({ ...project, ...form }))

      if (form.useTiers) {
        setStep(4)
      } else {
        setStep(5)
      }
    } catch (error) {
      console.error(error)
      // todo: set form error
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={stepThreeSchema}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        values,
      }) => (
        <form
          className='bg-clear flex w-full flex-col space-y-2 px-8 sm:p-0'
          onSubmit={handleSubmit}
        >
          <div className='w-full space-y-1 sm:flex sm:grid-cols-2 sm:flex-row'>
            <div className='bg-clear flex flex-col px-4 py-4 sm:w-1/3'>
              <p className='pt-12 font-sans text-4xl font-bold text-neutral-900 sm:px-12'>
                Set your target
              </p>
              <p className='text-md pt-4 font-sans font-light text-neutral-700 sm:px-12'>
                How much are you looking to raise and how many NFTs do you want
                to mint?
              </p>
            </div>

            <div className='border-1 flex-1 flex-col rounded-l-3xl rounded-r-3xl border border-white bg-white bg-opacity-50 p-8 bg-blend-saturation sm:rounded-r-none sm:rounded-l-3xl sm:px-36 sm:py-24'>
              <div className='flex w-full flex-col'>
                <p className='mb-2 text-2xl font-bold text-neutral-900'>
                  Please select your raise currency
                </p>
                <p className='text-md py-2 font-sans font-light text-neutral-700'>
                  Your crowdfund campaign will only be able to accept this
                  currency as participation payment
                </p>

                <div className='mt-4 w-full space-y-7 lg:flex lg:space-y-0 lg:space-x-12'>
                  {fundingTypes.map((type) => (
                    <div
                      key={type.value}
                      className={classNames(
                        'flow-root w-full rounded-lg bg-slate-200 py-4 pl-4 pr-20 shadow-md hover:cursor-pointer',
                        type.value === values.fundingType
                          ? 'shadow-gray-700'
                          : 'shadow-gray-100',
                      )}
                      onClick={() => setFieldValue('fundingType', type.value)}
                    >
                      <div className='flex'>
                        <img
                          src={`/${type.value}.svg`}
                          className='mr-4 h-12'
                          style={{ width: 40, height: 40 }}
                        />
                        <span className='my-auto text-xl font-bold'>
                          {type.label}
                        </span>
                      </div>
                      <div className='mt-5 mb-1'>
                        <p className='text-sm text-gray-500'>Blockchain:</p>
                      </div>

                      <div className='text-gray-500'>
                        <div className='flex'>
                          <img
                            src={
                              type.value === 'eth' ? '/polygon.svg' : '/icp.svg'
                            }
                            className='my-auto mr-4 h-5'
                            style={{ width: 30, height: 30 }}
                          />
                          <p className='text-lg font-bold'>
                            {type.value === 'eth' ? 'Polygon' : 'IC'}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <p className='mt-8 text-2xl font-bold text-neutral-900'>
                How much would you like to raise?
              </p>
              <div className='flex'>
                <div className='w-full lg:w-1/3 xl:w-1/4'>
                  <Input
                    name='targetAmount'
                    value={values.targetAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Enter target amount'
                    type='text'
                  />
                </div>
                <div className='my-auto ml-2 flex'>
                  <img
                    src={`/${values.fundingType}.svg`}
                    className={classNames(
                      'my-auto mr-2',
                      values.fundingType === 'eth' ? 'h-7' : 'h-5',
                    )}
                  />
                  <p className='text-lg font-bold text-neutral-900'>
                    {values.fundingType.toUpperCase()}
                  </p>
                </div>
              </div>

              <p className='my-4 flex items-center rounded-2xl bg-gray-50 p-4 text-sm'>
                <InformationCircleIcon className='h-12  rounded-full text-blue-500' />
                <span className='ml-4 flex'>
                  Fees apply - please refer to our
                  <Link to='/pages/pricing'>
                    <span className='ml-1 font-bold text-blue-500'>
                      Pricing page
                    </span>
                  </Link>
                </span>
              </p>

              <div className='flex w-full flex-col space-y-1'>
                <p className='my-5 text-2xl font-bold text-neutral-900'>
                  Would you like to include tiers in your round?
                </p>

                <div className='my-auto space-y-4 lg:flex lg:space-y-0 lg:space-x-4'>
                  <button
                    type='button'
                    onClick={() => setFieldValue('useTiers', true)}
                    className={classNames(
                      'flex w-full flex-row justify-center rounded-xl py-3 px-4 text-base font-medium tracking-wider shadow-xl hover:bg-blue-700 lg:w-1/2',
                      values.useTiers
                        ? 'bg-blue-600 text-white'
                        : 'bg-white text-blue-600 hover:text-white',
                    )}
                  >
                    <span className='my-auto'>Yes, I would like tiers</span>
                  </button>
                  <button
                    type='button'
                    onClick={() => setFieldValue('useTiers', false)}
                    className={classNames(
                      'flex w-full flex-row justify-center rounded-xl py-3 px-4 text-base font-medium tracking-wider shadow-xl hover:bg-blue-700 lg:w-1/2',
                      !values.useTiers
                        ? 'bg-blue-600 text-white'
                        : 'bg-white text-blue-600 hover:text-white',
                    )}
                  >
                    <span className='my-auto'>
                      No, I would like 1 donation amount
                    </span>
                  </button>
                </div>

                {!values.useTiers && (
                  <div>
                    <p className='mt-4'>
                      How many NFTs would you like to include in your
                      collection?
                    </p>
                    <Input
                      name='nftVolume'
                      value={values.nftVolume}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Enter target amount'
                      type='number'
                      endItem={() => (
                        <p className='font-bold text-black'>NFTs</p>
                      )}
                    />
                  </div>
                )}
              </div>
              <p className='my-4 flex items-center rounded-2xl bg-gray-50 p-4 text-sm'>
                <InformationCircleIcon className='h-12  rounded-full text-blue-500' />
                <span className='ml-4 flex'>
                  The average donation in crowdfunding is £320. So if you would
                  like to raise £100,000 - make sure you set your NFT collection
                  to roughly 312 NFTs.
                </span>
              </p>
              {Object.values(errors).length > 0 && (
                <div className='flex w-full flex-row items-center text-sm text-red-500'>
                  {Object.values(errors)[0]}
                </div>
              )}

              <button
                disabled={isLoading === true}
                type='submit'
                className={`
                  flex w-full flex-row justify-center rounded-xl bg-blue-600 py-3 
                  px-4 text-base font-medium tracking-wider text-white
                  shadow-xl hover:bg-blue-700
                `}
              >
                {!isLoading && <span>Next</span>}

                {isLoading && (
                  <span className='h-5 w-5'>
                    <Spinner show={true} />
                  </span>
                )}
              </button>

              <button
                className='w-full appearance-none py-4 px-4 text-center text-xs text-gray-500 focus:outline-none'
                onClick={previousStep}
                type='button'
              >
                &larr; Go back
              </button>
            </div>
          </div>

          <p className={` w-full py-4 px-4 text-center text-xs text-gray-500`}>
            By continuing, you agree to CrowdFund NFTs Terms and acknowledge
            receipt of our Privacy Policy.
          </p>
        </form>
      )}
    </Formik>
  )
}

export default FundingTypeForm
