import React from 'react'
import NftRewardForm from '../components/nftRewardForm'
// import { ProjectFormProvider } from '../providers/projectFormProvider'
import { NftRewardFormProvider } from '../providers/nftRewardFormProvider'

const NftReward = () => {
  return (
    <NftRewardFormProvider>
      <NftRewardForm />
    </NftRewardFormProvider>
  )
}

export default NftReward

