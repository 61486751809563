import React from 'react'
import confetti from 'canvas-confetti'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const MintSuccess = () => {
  useEffect(() => {
    confetti()
  }, [])

  return (
    <div className='flex w-full flex-col'>
      <div className='flex h-screen w-screen flex-col justify-center bg-white'>
        <div className='mx-auto flex w-full max-w-6xl flex-col items-center space-y-4 px-4'>
          <p className='text-center text-5xl font-bold text-blue-600 lg:text-7xl'>
            Success!
          </p>
          <p className='max-w-xl text-center text-gray-500'>
            Your Funded NFT has been minted on Ethereum. It will soon appear in
            your MetaMask wallet and will be tradable on OpenSea.
          </p>

          <p className='max-w-xl text-center'>
            Use your Ethereum NFT to participate in ETH round whitelists!
          </p>
          <Link to='/'>
            <span className='text-sm hover:text-blue-600'>
              Back to homepage
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MintSuccess
