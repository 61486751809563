import React from 'react'
import { create } from 'react-modal-promise'
import { wallets } from '../../helpers/wallets'
import Modal from '../shared/modal'

const SelectWalletModal = ({ isOpen, onResolve, onReject }) => {
  const handleWalletConnect = (provider) => {
    wallets[provider]
      .connect(['3fhg4-qiaaa-aaaak-aajiq-cai'])
      .then((principal) => onResolve({ provider, principal }))
      .catch(onReject)
  }

  return (
    <Modal show={isOpen} size='sn' width='100%' height='100%'>
      <div className='mx-auto flex h-full w-full flex-col items-center justify-center space-y-4'>
        <img src='/funded-logo.svg' className='w-1/3' />
        <p>Select your wallet</p>
        {Object.keys(wallets).map((w) => (
          <button
            key={w}
            type='button'
            className={`mt-5 flex flex-row items-center justify-center space-x-1 rounded border border-transparent bg-slate-600 px-4 py-2 font-medium text-black shadow-xl hover:bg-slate-400`}
            onClick={() => handleWalletConnect(w)}
          >
            <img src={`/${w}.png`} className='h-8' />
            <span className='px-2 font-medium text-white'>
              {'Connect ' + w[0].toUpperCase() + w.slice(1) + ' Wallet'}
            </span>
          </button>
        ))}
        <button
          onClick={() => onReject('Manually closed')}
          className='hover:opacity-80'
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export const selectWalletModalPromise = create(SelectWalletModal)
