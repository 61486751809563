import { useEffect, useState } from 'react'

const useCryptoPrice = () => {
  const [cryptoPrices, setCryptoPrices] = useState({
    ICP: 0,
    ETH: 0,
  })

  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,internet-computer&vs_currencies=usd',
    )
      .then((response) => response.json())
      .then((response) => {
        setCryptoPrices({
          ETH: response['ethereum'].usd,
          ICP: response['internet-computer'].usd,
        })
      })
  }, [])

  return { cryptoPrices }
}

export default useCryptoPrice
