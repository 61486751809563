import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { format, parseISO } from 'date-fns'

const POST_QUERY = gql`
  query GetPost($urlSlug: String!) {
    post(urlSlug: $urlSlug) {
      id
      title
      author
      description
      kind
      coverPicture
      createdAt
    }
  }
`

const ViewBlog = () => {
  const { urlSlug } = useParams()
  const { data } = useQuery(POST_QUERY, { variables: { urlSlug } })

  console.log(data)

  return (
    <>
      <div className='relative overflow-hidden bg-white py-16'>
        <div className='relative px-6 lg:px-8'>
          <div className='mx-auto max-w-prose text-lg'>
            <h1>
              <span
                className='mt-2 block bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text pb-4 text-center text-3xl
                font-bold leading-8 tracking-tight text-gray-900 text-transparent sm:text-4xl
              '
              >
                {data?.post?.title}
              </span>
            </h1>
          </div>
          <div className='prose prose-lg prose-indigo mx-auto mt-6 text-gray-500'>
            <img
              className='w-full rounded-lg object-cover mb-0'
              src={data?.post?.coverPicture}
              width={1310}
              height={873}
            />
            <div className='flex items-center text-xs font-medium mb-6'>
              {data?.post?.author === 'Funded' ?
                <img src='/funded_avatar.png' alt='' /> :
                <img src='/Ronan_avatar.png' alt='' />
              }
              <p className='text-gray-900 ml-2'>
                {data?.post?.author}
              </p> 

              {data?.post?.createdAt &&
                <div className='ml-4 text-gray-500'>
                  <time dateTime={data?.post?.createdAt}>
                    {format(parseISO(data?.post?.createdAt), 'dd/MM/yyyy')}
                  </time>
                </div>
              }
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: data?.post?.description }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewBlog
