import React from 'react'
import { Link } from 'react-router-dom'

const FAQS = [
  {
    question: 'What is an NFT?',
    answer:
      'An NFT, or non-fungible token, is a unique digital asset that is stored on a blockchain. On Funded, NFTs are used as both a proof of participation in a project, as well as an access token to the set rewards.',
  },
  {
    question: "I  backed a project, why can't I see the NFT in my wallet?",
    answer:
      'You will receive your NFT only if the project reaches its funding goal. If the project does not reach its funding goal, no NFTs will be distributed, and you will receive a full refund (minus transaction fees). Once the project is fully funded, we will airdrop the NFTs to your  wallet.',
  },
  {
    question:
      'Is there a limit to the number of NFTs that can be issued for a project?',
    answer:
      "Yes, there may be a limit to the number of NFTs that can be issued for a project, depending on the project's design and goals. We will communicate the NFT allocation limits and other project details to participants before the campaign begins.",
  },
  {
    question: 'How do I collect my rewards?',
    answer:
      'It is the responsibility of the project creator to provide you with your rewards. If you need to contact the project creator, we provide Discord and Twitter links for you to get in touch with the projects that are live on Funded.',
  },
  {
    question: 'Who do I contact for support? ',
    answer:
      'If you have any questions or concerns, please contact Funded  at the following email address: info@funded.app',
  },
]

const General = () => {
  return (
    <div
      id='nft-collection-nfts'
      className='flex w-full flex-col items-start space-y-5'
    >
      {FAQS.map(({ question, answer }) => (
        <div className='flex w-full flex-col space-y-3 rounded-2xl bg-white p-8 text-gray-600 shadow'>
          <p className='text-2xl font-bold text-black'>{question}</p>
          <p>{answer}</p>
        </div>
      ))}

      <Link to='/pages/terms'>
        <div
          className={`w-full cursor-pointer rounded-lg bg-blue-600 px-4 py-3 text-center text-sm text-white hover:bg-blue-700 focus:outline-none`}
        >
          Read more about us in our T&amp;C's
        </div>
      </Link>
    </div>
  )
}

export default General
