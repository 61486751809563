import React from 'react'
import ProjectList from '../components/home/project-list'
import Hero from '../components/home/hero'
import CategoryRow from '../components/shared/categoryRow'
import Featured from '../components/home/featured'
import Entrepot from '../components/home/entrepot'
import Getstarted from '../components/home/getstarted'
import { useNavigate } from 'react-router-dom'
import Whyfunded from '../components/home/whyfunded'


const HomePage = () => {
  const navigate = useNavigate()

  return (
    <div className='w-full'>
      <Hero />
      <Featured />
      <Whyfunded />

      <CategoryRow
        onClick={(category) => navigate(`/projects?category=${category}`)}
      />

      <ProjectList
        header='Live Projects'
        statuses={['whitelist', 'live']}
      />

      <ProjectList
        header='Projects Going Live Soon'
        statuses={['approved']}
      />

      <ProjectList
        header='Fully Funded Projects'
        statuses={['fully_funded']}
      />

      <Getstarted />
      <Entrepot />
    </div>
  )
}

export default HomePage
