import React from 'react'
import { Link } from 'react-router-dom'

const incentives = [
  {
    name: 'A tamper-proof crowdfunding model',
    imageSrc: '/safe_icon.svg',
    description:
      'Our funds management system is run by a fully automated network of smart contracts.',
    id: '1',
    read: 'Read more',
    href: '/pages/tamperproof-model',
  },
  {
    name: 'Rewards are tied to digital contracts (NFTs)',
    imageSrc: '/gift_icon.svg',
    description:
      'By making rewards tradable, backers can monetise their participation and founders get long term revenue.',
    id: '2',
    read: 'Read more',
    href: '/pages/NFT-reward-system',
  },
  {
    name: 'Global audience',
    imageSrc: '/globe_icon.svg',
    description:
      'Fiat restriction belongs to the past. ETH, BTC and ICP can be easily purchased on Binance, Coinbase, Kraken…',
    id: '3',
    read: 'Read more',
    href: '/pages/global-audience',
  },
]

export default function Whyfunded() {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-5xl px-8  py-12 sm:px-6 lg:px-0'>
        <div className='rounded-2xl bg-white '>
          <div className='mx-auto max-w-xl lg:max-w-none'>
            <div className='text-left'>
              <h2 className='text-4xl font-bold tracking-tight text-gray-900'>
                Why use Funded?{' '}
              </h2>
            </div>
            <div className='mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3'>
              {incentives.map((incentive) => (
                <div
                  key={incentive.name}
                  className='text-center sm:flex sm:text-left lg:block lg:text-left'
                >
                  <div className='sm:flex-shrink-0'>
                    <div className='mb-4 flow-root'>
                      <img
                        className='mx-auto h-20 w-20'
                        src={incentive.imageSrc}
                        alt=''
                      />
                    </div>
                  </div>
                  <span className='inline-flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-blue-500 to-blue-600 px-3.5 py-1.5 text-xl font-semibold text-white'>
                    {incentive.id}
                  </span>
                  <div className='mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0'>
                    <h3 className='text-2xl font-semibold text-gray-900'>
                      {incentive.name}
                    </h3>
                    <p className='text-md mt-2 text-gray-500'>
                      {incentive.description}
                    </p>
                    <Link to={incentive.href}>
                      <div className='text-blue-700 hover:text-gray-900'>
                        {incentive.read}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
