import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ProjectList from '../components/home/project-list'
import CategoryRow from '../components/shared/categoryRow'

const Projects = () => {
  const location = useLocation()
  const [manuallySetCategory, setManuallySetCategory] = useState()

  const query = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location])

  const category = query.get('category')
  const searchCategory = manuallySetCategory || category

  return (
    <>
      <div className='mx-auto mt-20 mb-2 w-full max-w-5xl px-4 font-medium uppercase text-gray-500'>
        {searchCategory && (
          <p className=''>
            Results for category: <b>{searchCategory}</b>
          </p>
        )}
      </div>
      <CategoryRow
        selectedCategory={searchCategory}
        onClick={setManuallySetCategory}
      />
      <ProjectList
        header='Live Projects'
        statuses={['whitelist', 'live']}
        categories={[searchCategory]}
      />
      <ProjectList
        header='Projects Going Live Soon'
        statuses={['approved']}
        categories={[searchCategory]}
      />
      <ProjectList
        header='Fully Funded Projects'
        statuses={['fully_funded']}
        categories={[searchCategory]}
      />
      <ProjectList
        header='Closed Projects'
        statuses={['closed']}
        categories={[searchCategory]}
      />
    </>
  )
}

export default Projects
