import React from 'react'
import ProjectForm from '../components/projectForm'
import { ProjectFormProvider } from '../providers/projectFormProvider'

const NewProject = () => {
  return (
    <ProjectFormProvider>
      <ProjectForm />
    </ProjectFormProvider>
  )
}

export default NewProject
