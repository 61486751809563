import React, { useMemo } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const ALL_TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline', 'strike'], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['clean'], // remove formatting button
  ['image'],
  ['video'],
]

const RichTextArea = ({ name, onChange, value, label, exclude }) => {
  const toolbarOptions = useMemo(() => {
    return ALL_TOOLBAR_OPTIONS.filter((o) => {
      return o.some((x) => {
        if (typeof x === 'string') {
          return !(exclude || []).includes(x)
        }

        if (typeof o === 'object' && !Array.isArray(o)) {
          return Object.keys(o).some((x) => !(exclude || []).includes(x))
        }

        if (typeof o === 'object' && Array.isArray(o)) {
          return o.some((a) =>
            Object.values(a).some((b) => (exclude || []).includes(b)),
          )
        }

        return true
      })
    })
  }, [exclude])

  const quillModules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
      },
    }
  }, [toolbarOptions])

  return (
    <div className='w-full rounded-full '>
      {label && (
        <label
          htmlFor={name}
          className='font-regular w-full rounded-none
        text-sm text-neutral-700 placeholder-neutral-500
        '
        >
          {props.label}
        </label>
      )}

      <ReactQuill
        onChange={onChange(name)}
        value={value}
        className='mb-[60px] h-[300px] rounded-full'
        theme='snow'
        modules={quillModules}
      />
    </div>
  )
}

export default RichTextArea
