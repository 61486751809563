import React from 'react'
import ViewBlog from '../components/blog/viewBlog'
import Footer from '../components/shared/footer'
import Navbar from '../components/shared/navbar'

const SinglePost = () => {
  return (
    <div className='w-full'>
      <ViewBlog />
    </div>
  )
}

export default SinglePost
