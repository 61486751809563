import React from 'react'
import { Link } from 'react-router-dom'
import Team from '../components/about/team'
import Whyfunded from '../components/home/whyfunded'

const About = () => {
  return (
    <div className='flex w-full flex-col'>
      <div className='bg-white'>
        <main>
          <div className='mx-auto mt-24 max-w-7xl px-4 sm:mt-24 sm:px-6'>
            <div className='text-center'>
              <h1 className='text-6xl font-bold tracking-tight text-neutral-900 sm:text-5xl md:text-7xl'>
                <span className='block md:text-7xl'>Crowdfund the</span>
                <span className='block text-neutral-900'>
                  {' '}
                  <span className='bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent'>
                    future!
                  </span>
                </span>
              </h1>
              <p className='font-regular mx-auto mt-3 max-w-md text-xl tracking-tight text-neutral-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl'>
                Using smart contract technology to build a safer, cheaper and
                global crowdfunding service - with a new NFT model.
              </p>
            </div>
            <div className='bg-white'>
              <div className='mx-auto max-w-7xl py-8 px-4 sm:py-16 sm:px-6 lg:px-8'>
                <div className='relative overflow-hidden rounded-lg'>
                  <div className='absolute inset-0'></div>
                  <div className='relative bg-blue-600 py-32 px-6 sm:py-20 sm:px-12 lg:px-16'>
                    <div className='relative mx-auto flex max-w-3xl flex-col items-center text-center'>
                      <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                        <span className='block sm:inline'>
                          Project Creator Bible
                        </span>
                      </h2>
                      <p className='mt-3 text-xl text-white'>
                        Read our guide before you launch your project!
                      </p>
                      <Link to='/pages/founders-bible'>
                        <div className='mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto'>
                          Link to Project Creator Bible
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='relative py-4 sm:py-8'>
              <div
                className='absolute inset-0 flex flex-col'
                aria-hidden='true'
              >
                <div className='flex-1' />
                <div className='w-full flex-1 bg-white' />
              </div>
              <div className='aspect-w-16 aspect-h-9 relative mx-auto max-w-5xl rounded-xl'>
                <iframe
                  width='560'
                  height='315'
                  src='https://www.youtube.com/embed/AS_FVAoRpMk'
                  className='rounded-2xl'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <Whyfunded />
            <div className='mx-auto max-w-5xl rounded-xl bg-white'>
              <div className='mx-auto max-w-5xl py-2 px-4 sm:py-16 sm:px-6 lg:px-8'>
                <h2 className='text-center text-xl font-medium text-neutral-300'>
                  Trusted by premier Internet Computer companies
                </h2>
                <div className='mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5'>
                  <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                    <img className='h-8' src='/kinic.png' alt='Tuple' />
                  </div>
                  <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                    <img className='h-8' src='/bink.png' alt='Mirage' />
                  </div>
                  <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                    <img
                      className='h-8'
                      src='/cosmicraftsgrey.png'
                      alt='Cosmicrafts'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center md:col-span-3 lg:col-span-1'>
                    <img className='h-8' src='/cubetopia.png' alt='Cubetopia' />
                  </div>
                  <div className='col-span-2 flex justify-center md:col-span-3 lg:col-span-1'>
                    <img className='h-8' src='/catalyze.png' alt='Catalyze' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className='bg-white'>
        <div className='mx-auto max-w-5xl py-8 px-4 sm:px-6 lg:px-0'>
          <div className='overflow-hidden rounded-lg bg-gradient-to-br from-neutral-100 to-white lg:grid lg:grid-cols-2 lg:gap-4'>
            <div className='px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:pl-20'>
              <div className='lg:self-center'>
                <h2 className='font-regular text-3xl tracking-tight text-neutral-900 sm:text-4xl'>
                  <span className='block'>Ready to get started?</span>
                  <span className='font-regular block'>
                    Join{' '}
                    <span className='font-bold'>
                      fun<span className='text-blue-600'>ded</span>
                    </span>{' '}
                    today.
                  </span>
                </h2>
                <Link to='/projects/new'>
                  <div className='mt-8 inline-flex items-center rounded-full border border-transparent bg-black px-5 py-3 text-base font-medium text-white shadow hover:bg-neutral-900'>
                    Start a Crowdfund
                  </div>
                </Link>
              </div>
            </div>
            <div className='aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 -mt-6'>
              <img
                className='translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20'
                src='/dashboard2.png'
                alt='App screenshot'
              />
            </div>
          </div>
        </div>
      </div>
      <Team />
    </div>
  )
}

export default About
