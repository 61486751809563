export const escrowIdlFactory = ({ IDL }) => {
  const AccountIdText = IDL.Text
  const Result_1 = IDL.Variant({ ok: IDL.Null, err: IDL.Text })
  const Result = IDL.Variant({ ok: AccountIdText, err: IDL.Text })
  const NFTStats = IDL.Record({
    sold: IDL.Nat,
    openSubaccounts: IDL.Nat,
    number: IDL.Nat,
    priceE8S: IDL.Nat,
    priceSatoshi: IDL.Nat,
    oversellNumber: IDL.Int,
  })
  const EscrowStats = IDL.Record({
    endTime: IDL.Int,
    nftStats: IDL.Vec(NFTStats),
  })
  return IDL.Service({
    cancelTransfer: IDL.Func([AccountIdText], [], []),
    confirmTransfer: IDL.Func([AccountIdText], [Result_1], []),
    getNewAccountId: IDL.Func([IDL.Principal, IDL.Nat, IDL.Text, IDL.Text, IDL.Text], [Result], []),
    getAccountsInfo: IDL.Func([], [IDL.Text], ['query']),
    getLogs: IDL.Func([], [IDL.Text], ['query']),
    getStats: IDL.Func([], [EscrowStats], ['query']),
  })
}
