import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

const PAGE_QUERY = gql`
  query Page($urlSlug: String!) {
    page(urlSlug: $urlSlug) {
      title
      subtitle
      body
    }
  }
`

const Page = () => {
  const { urlSlug } = useParams()
  const { data } = useQuery(PAGE_QUERY, {
    variables: { urlSlug },
  })

  return (
    <>
      <header className='relative flex w-full flex-col md:h-96 lg:flex-row lg:space-x-5 lg:bg-gray-100 lg:bg-opacity-100'>
        <img src='/radiant-gradient.svg' className='w-full object-cover' />
        <div className='absolute top-0 left-0 flex h-full w-full flex-col justify-center px-4 py-10 text-white'>
          <div className='mx-auto w-full max-w-prose'>
            <h1 className='mb-5 text-3xl font-bold lg:text-5xl'>
              {data?.page?.title}
            </h1>

            <p className='mb-10 font-light text-gray-200 md:text-xl'>
              {data?.page?.subtitle}
            </p>
          </div>
        </div>
      </header>
      <div className='relative w-full bg-white'>
        <div className='mx-auto flex w-full max-w-5xl flex-col space-x-2 lg:flex-row'>
          <article
            className='prose relative mx-auto flex w-full flex-col space-y-4 p-4 leading-loose text-gray-700'
            dangerouslySetInnerHTML={{ __html: data?.page?.body }}
          />
        </div>
      </div>
    </>
  )
}

export default Page
