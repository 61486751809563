import React from 'react'
import PostList from '../components/blog/postList'

const Blog = () => {
  return (
    <div className='w-full'>
      <PostList />
    </div>
  )
}

export default Blog
