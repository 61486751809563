import React, { useCallback, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import useNftRewardForm from '../../hooks/useNftRewardForm'
import { tokenIdentifierFromIndex } from '../../utils/token.utils'
import { Spinner } from '../shared/loading-spinner'
import nftContract from '../../../abi/nft.json'
import { ethers } from 'ethers'
import { useNavigate } from 'react-router-dom'

const NFT_MINT_MESSAGE = gql`
  mutation GetNftMintMessage($principal: String!, $nftIndex: Int!) {
    getNftMintMessage(input: { principal: $principal, nftIndex: $nftIndex }) {
      error
      messageHash
      signature
      r
      s
      v
      metadata
    }
  }
`

const ConnectMetamaskForm = () => {
  const { nftID, principalID, configData } = useNftRewardForm()
  const navigate = useNavigate()
  const tokenIdentifier = tokenIdentifierFromIndex(
    configData?.config?.nftCanisterPrincipal,
    nftID,
  )
  const thumbnail = `https://${configData?.config?.nftCanisterPrincipal}.icp0.io/?tokenid=${tokenIdentifier}`
  const [getNftMintMessage, { data: mintData, loading }] =
    useMutation(NFT_MINT_MESSAGE)

  const mintNFT = useCallback(async () => {
    if (!mintData || mintData.getNftMintMessage.error) return

    await window.ethereum.request({ method: 'eth_requestAccounts' })
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      configData?.config?.ethChain,
    )

    const contract = new ethers.Contract(
      configData?.config?.nftSmartContractAddress,
      nftContract.abi,
      provider.getSigner(0),
    )

    const { r, s, v, metadata } = mintData.getNftMintMessage

    const tx = await contract.whitelistMintNFT(metadata, `${nftID}`, v, r, s)
    return tx
  }, [configData, mintData, nftID])

  const getNftMsg = async () => {
    getNftMintMessage({
      variables: {
        principal: principalID,
        nftIndex: nftID,
      },
    }).catch(console.log)
  }

  useEffect(() => {
    if (mintData) {
      mintNFT()
        .then((tx) => {
          console.log(tx)
          navigate('success', { replace: true })
        })
        .catch((e) => {
          window.alert(`Error Minting NFT: ${e}`)
        })
    }
  }, [mintData])

  return (
    <div className='bg-clear flex w-full flex-col space-y-2 px-8 md:p-0'>
      <div className='w-full space-y-1 md:grid-cols-2 md:flex-row lg:flex'>
        <div className='bg-clear flex flex-col px-4 py-4 md:w-1/2 lg:w-1/3'>
          <p className='pt-16 font-sans text-2xl font-bold tracking-tight text-neutral-900 md:px-12 md:text-4xl'>
            Connect your Metamask
          </p>
          <p className='text-md font-sans font-light text-neutral-500 md:px-12 md:pt-4'>
            For us to deliver new CrowdFund NFT
          </p>
        </div>
        <div className='border-1 flex-1 flex-col rounded-l-3xl rounded-r-3xl border border-white bg-white bg-opacity-50 p-8 bg-blend-saturation md:rounded-r-none md:rounded-l-3xl md:px-36 md:py-24'>
          <div className='flex w-full flex-col items-center justify-center'>
            <p className='mb-5 font-sans text-lg font-bold tracking-tight text-neutral-900 md:px-12 md:text-4xl'>
              CrowdFund NFT #{nftID}
            </p>
            <img src={thumbnail} className='mr-2 border shadow-xl' />
          </div>
          <div className='mt-12 flex w-full flex-col items-center justify-center'>
            <button
              className='mb-5 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-500 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 sm:w-1/2'
              onClick={getNftMsg}
              disabled={loading}
            >
              {loading ? (
                <span className='h-5 w-5'>
                  <Spinner show={true} />
                </span>
              ) : (
                <>
                  <img
                    src='/metamask.svg'
                    className='mr-2'
                    style={{ widht: 30, height: 30 }}
                  />
                  <span>Connect & Initiate Mint</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectMetamaskForm
