import { useCallback, useEffect, useMemo, useState } from 'react'
import escrowContract from '../../abi/escrow.json'
import { ethers } from 'ethers'

const ETHERSCAN_API_KEY = 'TWP22GUC5U35XA872NCP82DZFTQQJZYQFH'
const POLYGONSCAN_API_KEY = 'JW417XPPFNP8WN7D6MUCSDWHEDBFBKQZ7A'

export default function useETHEscrow(contractAddress, blockchain, fundingType) {
  const [stats, setStats] = useState()

  const backProject = useCallback(
    async (tier, price) => {
      const blockchainId = parseInt(blockchain)
      if (window.ethereum.networkVersion !== blockchain) {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${blockchainId.toString(16)}` }],
        })
      }

      await window.ethereum.request({ method: 'eth_requestAccounts' })
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        blockchainId,
      )

      const contract = new ethers.Contract(
        contractAddress,
        escrowContract.abi,
        provider.getSigner(0),
      )

      const tx = await contract.buyNFT(tier, {
        value: ethers.utils.parseEther(`${price}`),
      })
      return tx
    },
    [contractAddress, blockchain],
  )

  const fetchStats = useCallback(async () => {
    const blockchainId = parseInt(blockchain)

    const provider = new ethers.providers.EtherscanProvider(
      (network = blockchainId),
      fundingType === 'matic' ? POLYGONSCAN_API_KEY : ETHERSCAN_API_KEY,
    )

    const contract = new ethers.Contract(
      contractAddress,
      escrowContract.abi,
      provider,
    )

    const stats = await contract.getSoldCount()
    return stats.map((sold) => ({ sold }))
  }, [contractAddress, blockchain])

  useEffect(() => {
    fetchStats().then(setStats).catch(console.log)
  }, [fetchStats])

  return { backProject, stats }
}
