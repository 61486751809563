import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Principal } from '@dfinity/principal'
import { selectWalletModalPromise } from '../components/modals/selectWalletModal'
import { createActor } from '../helpers/createActor'
import { escrowIdlFactory } from '../ic/idlFactories/escrow'
import { escrowManagerIdlFactory } from '../ic/idlFactories/escrowManager'
import { wallets } from '../helpers/wallets'

export default function useICEscrow(projectExternalId) {
  const [projectEscrow, setProjectEscrow] = useState()
  const [stats, setStats] = useState([])

  const escrowActor = useMemo(() => {
    return createActor('3fhg4-qiaaa-aaaak-aajiq-cai', escrowManagerIdlFactory)
  }, [])

  const backProject = useCallback(
    async (tierIndex) => {
      if (!projectEscrow) return

      const connectedWallet = await selectWalletModalPromise()
      const newAccount = await projectEscrow.getNewAccountId(
        Principal.from(connectedWallet.principal),
        tierIndex,
        'ICP',
        '',
        '',
      )
      if (newAccount.err) return Promise.reject(newAccount.err)

      const paymentResponse = await wallets[connectedWallet.provider].pay(
        newAccount.ok,
        stats[tierIndex].priceE8S,
      )

      if (paymentResponse.Err)
        return Promise.reject(Object.keys(paymentResponse.Err)[0])

      await projectEscrow.confirmTransfer(newAccount.ok)
    },
    [projectEscrow, stats],
  )

  useEffect(() => {
    if (!projectExternalId || !escrowActor) return

    escrowActor
      .getProjectEscrowCanisterPrincipal(+projectExternalId)
      .then((response) => {
        if (response.length > 0) {
          setProjectEscrow(createActor(response[0], escrowIdlFactory))
        }
      })
      .catch(console.log)
  }, [projectExternalId, escrowActor])

  useEffect(() => {
    if (!projectEscrow) return

    projectEscrow
      .getStats()
      .then((response) => {
        const mapped = response.nftStats.map(
          ({ number, oversellNumber, priceE8S, priceSatoshi, sold }) => {
            return {
              number: Number(number),
              oversellNumber: Number(oversellNumber),
              priceE8S: Number(priceE8S),
              priceSatoshi: Number(priceSatoshi),
              sold: Number(sold),
            }
          },
        )

        setStats(mapped)
      })
      .catch(console.log)
  }, [projectEscrow])

  return { stats, backProject, escrow: projectEscrow }
}
