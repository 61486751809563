import React from 'react'

export default function NFTs({ exampleNfts }) {
  return (
    <div
      id='nft-collection-nfts'
      className='flex w-full flex-col items-start rounded-2xl bg-white p-8 shadow'
    >
      <p className='text-2xl font-bold text-black'>NFTs</p>

      <p className='my-2 w-full font-light text-neutral-500'>
        If the project reaches its funding goal, participating users will be
        randomly allocated an NFT from the project's collection, and in the case
        of tiered projects, each tier comes with its own collection, with
        participants randomly allocated an NFT from the corresponding one.
      </p>

      {(exampleNfts || []).length > 0 && (
        <div className='grid w-full grid-cols-1 gap-6 lg:grid-cols-3'>
          {exampleNfts.map((nft) => (
            <div key={nft.id} className='flex w-full flex-col space-y-2'>
              <p className='font-semibold'>{nft.id}</p>
              <figure className='w-full rounded-lg border bg-gray-300'>
                <img src={nft.asset} />
              </figure>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
