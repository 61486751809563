import React, { createContext } from 'react'
import { gql, useQuery } from '@apollo/client'

export const UserContext = createContext()

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    activeUser {
      id
      firstName
      lastName
      bio
    }
  }
`

const UserProvider = ({ children }) => {
  const { data, loading: userLoading } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
  })

  return (
    <UserContext.Provider value={{ user: data?.activeUser, userLoading }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
