import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import useDebouncedState from '../../../hooks/useDebouncedState'

const VISIBLE_SEARCH_ITEMS = 5

const PROJECTS_QUERY = gql`
  query Projects($keywords: String) {
    projects(keywords: $keywords) {
      id
      urlSlug
      title
      coverImage
      aasmState
      totalGoal
      fundingType
      user {
        firstName
        lastName
      }
    }
  }
`

const Search = () => {
  const [debouncedSearchTerm, setSearchTerm, searchTerm] = useDebouncedState(
    null,
    300,
  )
  const navigate = useNavigate()

  const [getProjects, { data: projectData, loading }] = useLazyQuery(
    PROJECTS_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (!debouncedSearchTerm) return

    getProjects({ variables: { keywords: debouncedSearchTerm } })
  }, [debouncedSearchTerm])

  const foundProjects = projectData?.projects || []

  const renderSearchResults = () => {
    if (!foundProjects || foundProjects.length === 0) return

    return (
      <div className='absolute right-0 mt-2 w-80 origin-top-right rounded-md bg-white p-3 shadow-lg ring-1 ring-black ring-opacity-5'>
        <div>
          {foundProjects
            ?.slice(0, VISIBLE_SEARCH_ITEMS)
            .map(({ title, coverImage, id, urlSlug, user }) => {
              return (
                <Link to={`/projects/${urlSlug}`} key={id}>
                  <a className='flex flex-row items-center rounded py-2 hover:bg-slate-100'>
                    <img
                      src={coverImage}
                      className='ml-2 h-12 w-12 rounded-md object-cover'
                    />
                    <div className='ml-3'>
                      <p className='text-sm text-gray-700'>{title}</p>
                      <p className='text-sm text-gray-400'>
                        by {`${user.firstName} ${user.lastName}`}
                      </p>
                    </div>
                  </a>
                </Link>
              )
            })}
        </div>
      </div>
    )
  }

  return (
    <div className='relative ml-3 hidden text-gray-600 md:block'>
      <input
        className='h-8 rounded-lg border border-gray-100 bg-gray-100 px-2 text-sm focus:outline-none'
        type='search'
        name='search'
        placeholder='Search projects'
        value={searchTerm ?? ''}
        onChange={({ target: { value } }) => setSearchTerm(value)}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            navigate(`/projects?search=${searchTerm}`)
            setSearchTerm()
          }
        }}
      />
      {renderSearchResults()}
    </div>
  )
}

export default Search
