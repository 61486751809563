export default [
  { label: 'Art', value: 'art' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'IC Projects', value: 'ic-projects' },
  { label: 'Music', value: 'music' },
  { label: 'Supernova', value: 'supernova' },
  { label: 'Web3', value: 'web3' },
  { label: 'Charity', value: 'charity' },
  { label: 'Other', value: 'other' },
]
