import { Actor, HttpAgent } from '@dfinity/agent'

export const createActor = (canisterId, idlFactory, options) => {
  const agent = new HttpAgent({
    host: 'https://ic0.app',
    ...options?.agentOptions,
  })

  // if (process.env.NODE_ENV !== 'production') {
  //   agent.fetchRootKey().catch((err) => {
  //     console.warn(
  //       'Unable to fetch root key. Check to ensure that your local replica is running',
  //     )
  //     console.error(err)
  //   })
  // }

  return Actor.createActor(idlFactory, {
    agent,
    canisterId,
    ...options?.actorOptions,
  })
}
