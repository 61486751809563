import React from 'react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import End from './end'

export default function Navbar() {
  const [hasScrolled, setScrolled] = useState(false)

  useEffect(() => {
    const listener = document.addEventListener('scroll', (e) => {
      const scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 41) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    })

    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])

  return (
    <>
      <div
        className={classNames(
          'fixed z-20 w-full bg-white py-6 text-white',
          'border-b transition-all duration-300',
          hasScrolled ? 'border-gray-200' : 'border-transparent',
        )}
      >
        <div className='mx-auto flex w-full max-w-7xl flex-row  items-center justify-between px-4 sm:px-0'>
          <nav className='hidden space-x-2 lg:flex lg:flex-1'>
            <Link to='/about'>
              <div
                className={`
                                    transform cursor-pointer bg-transparent px-2 py-px text-sm
                                    text-neutral-900 transition duration-200 hover:text-gray-700 hover:underline
                                `}
              >
                About
              </div>
            </Link>
            <a
              href='https://marketplace.funded.app/'
              className={`
                                    transform cursor-pointer bg-transparent px-2 py-px text-sm
                                    text-neutral-900 transition duration-200 hover:text-gray-700 hover:underline
                                `}
            >
              Marketplace
            </a>
            <Link to='/blog'>
              <div
                className={`
                                    transform cursor-pointer bg-transparent px-2 py-px text-sm
                                    text-neutral-900 transition duration-200 hover:text-gray-700 hover:underline
                                `}
              >
                News
                <span className='ml-1 inline-flex items-center rounded-full bg-gradient-to-tr from-cyan-600 to-blue-600 px-2 py-px text-xs font-medium text-white'>
                  New
                </span>
              </div>
            </Link>
          </nav>
          <div className='flex flex-row items-center justify-center'>
            <Link to='/'>
              <div>
                <img
                  src='/funded-logo.svg'
                  className='cursor-pointer duration-200 hover:scale-105'
                  style={{ width: 140 }}
                  alt=''
                />
              </div>
            </Link>
          </div>
          <div className='flex flex-row items-center lg:flex-1 lg:justify-end'>
            <End />
          </div>
        </div>
      </div>
      <div className='h-12' />
    </>
  )
}
