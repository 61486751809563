import { Principal } from '@dfinity/principal'
import { StoicIdentity } from 'ic-stoic-identity'
import { AccountIdentifier } from '@dfinity/nns'
import { Actor, HttpAgent } from '@dfinity/agent'
import Provider from '@funded-labs/plug-inpage-provider'

const ua = navigator.userAgent.toLowerCase()
const isAndroid = ua.indexOf('android') > -1
const isApple = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1

const isMobile = isAndroid || isApple

if (!window.ic?.plug && isMobile) {
  Provider.exposeProviderWithWalletConnect({ window, debug: true })
}

import ledgerIdlFactory from '../idls/nns_ledger.did'
const LEDGER_CANISTER_ID = 'ryjl3-tyaaa-aaaaa-aaaba-cai'

const makePaymentArgs = (to, price) => {
  let accountIdBlob = AccountIdentifier.fromHex(to)
  accountIdBlob = accountIdBlob.bytes
  accountIdBlob = Object.keys(accountIdBlob).map((m) => accountIdBlob[m])
  return {
    to: accountIdBlob,
    fee: { e8s: 10000 },
    amount: {
      e8s: price,
    },
    memo: Math.floor(Math.random() * 1000),
    from_subaccount: [],
    created_at_time: [],
  }
}

export const wallets = {
  plug: {
    connect: async (whitelist = []) => {
      if (!window?.ic?.plug)
        return alert('Plug is not installed in your browser')

      const connected = await window.ic.plug.isConnected()

      if (!connected) {
        await window?.ic?.plug?.requestConnect({
          whitelist,
        })
      }

      await window.ic.plug.createAgent()
      const principal = await window.ic.plug.agent.getPrincipal()
      return Principal.from(principal).toText()
    },
    pay: (to, amount) => {
      const params = { to, amount }
      return window.ic.plug.requestTransfer(params)
    },
  },
  infinity: {
    connect: async () => {
      if (!window?.ic?.infinityWallet)
        return alert('Infinity is not installed in your browser')

      await window?.ic?.infinityWallet?.requestConnect()
      const principal = await window.ic.infinityWallet.getPrincipal()

      return Principal.from(principal).toText()
    },
    pay: (to, amount) => {
      return new Promise((resolve, reject) => {
        const transaction = {
          idl: ledgerIdlFactory,
          canisterId: LEDGER_CANISTER_ID,
          methodName: 'transfer',
          args: [makePaymentArgs(to, amount)],
          onSuccess: async (result) => {
            resolve(result)
          },
          onFail: (error) => {
            reject(error)
          },
        }

        window.ic.infinityWallet.batchTransactions([transaction])
      })
    },
  },
  stoic: {
    connect: async () => {
      const identity = await StoicIdentity.load()
      if (!identity) {
        const loadedIdenity = await StoicIdentity.connect()
        return loadedIdenity.getPrincipal()
      }

      return identity.getPrincipal()
    },
    pay: async (to, amount) => {
      let identity = await StoicIdentity.load()

      if (!identity) {
        identity = await StoicIdentity.connect()
      }

      const ledgerActor = Actor.createActor(ledgerIdlFactory, {
        agent: new HttpAgent({ host: 'https://ic0.app', identity }),
        canisterId: LEDGER_CANISTER_ID,
      })
      const args = makePaymentArgs(to, amount)

      return ledgerActor.transfer(args)
    },
  },
}
