import React from 'react'
import classNames from 'classnames'
import Search from './navbar/search'

const projectCategories = [
  'art',
  'gaming',
  'ic-projects',
  'music',
  'supernova',
  'web3',
  'charity',
  'other',
]

const CategoryRow = ({ onClick, selectedCategory }) => (
  <>
    <div className='mx-auto flex max-w-5xl grid-cols-2 flex-row pt-4 sm:pt-16'>
      <div className='flex'>
        <Search />
      </div>
      <div className='mx-auto mb-8 flex w-full max-w-5xl flex-wrap justify-center space-x-3 px-4 text-center'>
        {projectCategories.map((category, index) => (
          <div
            key={index}
            className={classNames(
              'rounded-full border border-gray-300 bg-white px-4 py-1 text-sm font-semibold shadow',
              selectedCategory === category
                ? 'text-blue-700'
                : 'text-neutral-800',
            )}
            style={{ cursor: 'pointer' }}
            onClick={() => onClick(category)}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  </>
)

export default CategoryRow
