import React, { useState } from 'react'
import useNftRewardForm from '../../hooks/useNftRewardForm'
import RewardProgressBar from './rewardProgressBar'
import ConnectWalletForm from './connectWalletForm'
import ConnectMetamaskForm from './connectMetamaskForm'

const NftRewardForm = () => {
  const { step } = useNftRewardForm()

  return (
    <div className='w-full bg-blue-50 sm:mx-auto '>
      <div className='flex flex-col bg-gradient-to-br from-white to-blue-200  pb-8 pt-24 '>
        <div>
          <div className='absolute top-36 flex  w-full flex-col space-y-3 px-12 sm:left-6 sm:w-48'>
            <p className='text-xs font-bold text-neutral-700 '>
              Step {step} of 2
            </p>
            <div className=''>
              <RewardProgressBar step={step} />
            </div>
          </div>
          {step === 1 && <ConnectWalletForm />}
          {step === 2 && <ConnectMetamaskForm />}
        </div>
      </div>
    </div>
  )
}

export default NftRewardForm
