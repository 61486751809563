import { Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

const REGISTER_USER = gql`
  mutation RegisterUser($email: String!, $password: String!) {
    registerUser(input: { email: $email, password: $password }) {
      user {
        id
      }
      errors {
        attribute
        messages
      }
    }
  }
`

const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email('Should be an e-mail format')
    .required('Enter your e-mail'),
  password: Yup.string().required('Enter your password'),
  passwordConfirmation: Yup.string()
    .required('Enter password confirmation')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  recaptcha: Yup.bool().required('Recaptcha should be passed'),
})

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
}

const Input = (props) => (
  <div className='field'>
    <label htmlFor={props.name}>{props.label}</label>
    <input
      {...props}
      className='-indblock focus:borderigo-500 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-indigo-500 sm:text-sm'
    />
    {props.error && <span className='text-sm text-red-500'>{props.error}</span>}
    {props?.apiErrors?.length > 0 && (
      <span className='text-sm text-red-500'>
        {props.apiErrors?.join(', ')}
      </span>
    )}
  </div>
)

const Register = () => {
  const [registerUser, { data: userData, loading }] = useMutation(REGISTER_USER)
  const navigate = useNavigate()

  useEffect(() => {
    if (!userData?.registerUser?.user) return

    navigate('/', { replace: true })
  }, [userData])

  const onSubmit = useCallback(
    ({ email, password }) => {
      registerUser({ variables: { email, password } })
    },
    [registerUser],
  )

  return (
    <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <Link to='/'>
          <img src='/funded-logo.svg' className='mx-auto h-12 w-auto' />
        </Link>
        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
          Create new account
        </h2>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={registerSchema}
            validateOnChange={false}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              errors,
              values,
            }) => (
              <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
                <Input
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='email'
                  label='E-mail'
                  autoComplete='username'
                  error={errors.email}
                  apiErrors={
                    userData?.registerUser?.errors && [
                      ...userData?.registerUser?.errors
                        ?.filter((error) => error.attribute === 'email')
                        ?.map((error) => error.messages)
                        ?.flat(),
                    ]
                  }
                />
                <Input
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='password'
                  autoComplete='new-password'
                  label='Password'
                  error={errors.password}
                />
                <Input
                  name='passwordConfirmation'
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='password'
                  autoComplete='new-password'
                  label='Password confirmation'
                  error={errors.passwordConfirmation}
                />
                <ReCAPTCHA
                  sitekey='6LeV7ysjAAAAAC2Jx7s2pNpbpXjVp0xhpOiM29P0'
                  onChange={() => {
                    setFieldValue('recaptcha', true)
                  }}
                />
                {errors.recaptcha && (
                  <span className='text-sm text-red-500'>
                    {errors.recaptcha}
                  </span>
                )}
                <button
                  type='submit'
                  name='commit'
                  disabled={loading}
                  className='flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Sign up
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Register
