import React from 'react'
const Input = (props) => {
  const { endItem: EndItem } = props

  return (
    <div className='mb-3'>
      {props.label && (
        <label htmlFor={props.name} className='mb-4 text-sm text-neutral-600'>
          {props.label}
        </label>
      )}
      <div className='border-1 relative mt-2 rounded-xl border border-neutral-300 bg-white py-3 px-3'>
        <input
          {...props}
          className={`
                        font-regular w-full
                        border-transparent bg-white text-sm
                        text-neutral-900 placeholder-neutral-500 focus:border-transparent focus:ring-0	
                    `}
        />
        {EndItem && (
          <div className='absolute top-0 right-0 flex h-full flex-col justify-center pr-4'>
            <EndItem />
          </div>
        )}
      </div>
    </div>
  )
}

export default Input
