import React from 'react'
import useCryptoPrice from '../../../hooks/useCryptoPrice'

const Tiers = ({
  tiers,
  currency,
  selectedTierState,
  stats,
  oversellPercentage,
}) => {
  const [selectedTier, setSelectedTier] = selectedTierState
  const { cryptoPrices } = useCryptoPrice()

  return (
    <div className='flex w-full flex-col'>
      <p className='mb-1 text-sm text-gray-700'>
        {tiers && tiers.length > 0 ? (
          <p className='mb-1 text-sm text-gray-700'>
            This project has {tiers.length} reward tiers.
          </p>
        ) : (
          <p className='mb-1 text-sm text-gray-700'>
            This project has no reward tiers.
          </p>
        )}
      </p>
      {tiers && tiers.length > 0 && (
        <div className='grid grid-cols-3 gap-2'>
          {tiers.map(({ number, price }, index) => {
            const sold = stats[index]?.sold || 0
            const isSelected = selectedTier === index
            const oversellNumber = (number * (oversellPercentage || 0)) / 100
            const soldOut = sold >= number + oversellNumber

            return (
              <div
                className={`${isSelected ? 'border-blue-600' : ''} ${
                  soldOut ? 'opacity-40' : 'cursor-pointer'
                } flex-1 rounded-lg border bg-gray-50 py-4`}
                onClick={() => !soldOut && setSelectedTier(index)}
              >
                <p
                  className={`text-center text-2xl font-bold ${
                    isSelected ? 'text-blue-600' : 'text-black'
                  }`}
                >
                  <p className='text-center text-xs font-light text-gray-400'>
                    Tier price
                  </p>
                  <div className='my-2'>
                    <div className='flex content-center items-center justify-center'>
                      <img
                        src={`/${currency.toLowerCase()}.svg`}
                        className='mr-1 inline-block h-4 w-4'
                      />
                      {price}
                    </div>

                    <p className='text-xs font-normal'>
                      $ {(cryptoPrices[currency] * price).toFixed(2)}
                    </p>
                  </div>
                  <p className='text-center text-xs font-light text-gray-400'>
                    {sold} / {number} sold
                  </p>
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Tiers
