import React from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { format, parseISO } from 'date-fns'

const POSTS_QUERY = gql`
  query Posts {
    posts {
      id
      urlSlug
      title
      author
      kind
      listPicture
      createdAt
    }
  }
`

const kindMap = {
  article: 'Article',
  press_release: 'Press Release',
  news: 'News',
  opinion: 'Opinion',
}

const PostList = () => {
  const { data: postData, loading } = useQuery(POSTS_QUERY)

  return (
    <>
      <div className='relative px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28'>
        <div className='absolute inset-0'>
          <div className='h-1/3 bg-white sm:h-2/3' />
        </div>
        <div className='relative mx-auto max-w-7xl'>
          <div className='text-center'>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              Funded
              <span className='bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent'>
                {' '}
                News
              </span>
            </h2>
            <p className='mx-auto mt-6 max-w-2xl text-xl text-gray-500 sm:mt-4'>
              Welcome to the Funded News. Here, you can keep up to date with
              latest developments and new in decentralized crowdfunding space.
            </p>
          </div>

          {!loading && (!postData?.posts || postData?.posts?.length === 0) ? (
            <div className='mx-auto mt-6 flex w-full max-w-5xl justify-center px-4'>
              <h3 className='text-lg font-semibold text-blue-600'>No posts</h3>
            </div>
          ) : (
            <div className='mx-auto mt-12 grid md:grid-cols-2 lg:max-w-none lg:grid-cols-3'>
              {postData?.posts.map((post) => (
                <div className='mb-8 h-[490px]' key={post.id}>
                  <Link to={`/blog/${post.urlSlug}`}>
                    <div className='mx-4 flex h-full flex-col overflow-hidden rounded-lg shadow-lg'>
                      <div className='flex-shrink-0'>
                        <img
                          className='h-48 w-full object-cover'
                          src={post.listPicture}
                          alt=''
                        />
                      </div>
                      <div className='flex flex-1 flex-col justify-between bg-white p-6'>
                        <div className='flex-1'>
                          <p className='text-sm font-medium text-indigo-600'>
                            {kindMap[post.kind]}
                          </p>
                          <p className='mt-2 text-xl font-semibold text-gray-900'>
                            {post.title.length > 135
                              ? post.title.substring(0, 134) + '...'
                              : post.title}
                          </p>
                        </div>
                        <div className='mt-6 flex items-center'>
                          <div className='flex-shrink-0'>
                            <div>
                              <span className='sr-only'>{post.author}</span>
                            </div>
                          </div>
                          <div>
                            <div className='flex items-center'>
                              {post?.author === 'Funded' ? (
                                <img src='/funded_avatar.png' alt='' />
                              ) : (
                                <img src='/Ronan_avatar.png' alt='' />
                              )}
                              <p className='ml-2 text-sm font-medium text-gray-900'>
                                {post.author}
                              </p>
                            </div>
                            <div className='mt-1 flex text-sm text-gray-500'>
                              <time dateTime={post.createdAt}>
                                {format(parseISO(post.createdAt), 'dd/MM/yyyy')}
                              </time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PostList
