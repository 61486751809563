import React from 'react'
import useProjectForm from '../../hooks/useProjectForm'
import FormSubmitStep from './formSubmitStep'
import FundingTypeForm from './fundingTypeForm'
import ProfileInfoForm from './profileInfoForm'
import ProgressBar from './progressBar'
import ProjectAttachments from './projectAttachments'
import ProjectDescriptionForm from './projectDescriptionForm'
import ProjectSummaryForm from './projectSummaryForm'
import SuccessPage from './successPage'
import TiersForm from './tiersForm'

const ProjectForm = () => {
  const { step } = useProjectForm()

  return (
    <div className='w-full bg-blue-50 sm:mx-auto '>
      <div className='flex flex-col bg-gradient-to-br from-white to-blue-200  pb-8 pt-24 '>
        <div>
          <div className='absolute top-36 flex  w-full flex-col space-y-3 px-12 sm:left-6 sm:w-48'>
            <p className='text-xs font-bold text-neutral-700 '>
              Step {step} of 7
            </p>
            <div className=''>
              <ProgressBar step={step} />
            </div>
          </div>
          {step === 1 && <ProfileInfoForm />}
          {step === 2 && <ProjectSummaryForm />}
          {step === 3 && <FundingTypeForm />}
          {step === 4 && <TiersForm />}
          {step === 5 && <ProjectAttachments />}
          {step === 6 && <ProjectDescriptionForm />}
          {step === 7 && <FormSubmitStep />}
          {step === 8 && <SuccessPage />}
        </div>
      </div>
    </div>
  )
}

export default ProjectForm
