import { getMetaValue } from './utils'
import { HttpLink, ApolloClient, InMemoryCache } from '@apollo/client'

const getClient = (token) => {
  const csrfToken = getMetaValue('csrf-token')

  const commonLinkOptions = {
    uri: '/graphql',
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken,
      Authorization: `Bearer ${token}`,
    },
  }

  const httpLink = new HttpLink(commonLinkOptions)

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  })
}

export default getClient
