import { ApolloProvider } from '@apollo/client'
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import getClient from '../graphqlClient'

export const SessionContext = createContext()

const SESSION_KEY = 'FUNDED_SESSION'

const SessionProvider = ({ children }) => {
  const [session, setSession] = useState()
  const resetTimeout = useRef()

  useEffect(() => {
    const unparsed = localStorage.getItem(SESSION_KEY)
    if (unparsed) setSession(JSON.parse(unparsed))
  }, [])

  useEffect(() => {
    if (!session) {
      clearTimeout(resetTimeout.current)
      return
    }

    const { expires_in, created_at } = session

    if (created_at + expires_in < Date.now() / 1000) {
      setSession()
      return
    }

    const expireIn = (created_at + expires_in) * 1000 - Date.now()

    resetTimeout.current = setTimeout(() => {
      setSession()
    }, expireIn)

    return () => {
      clearTimeout(resetTimeout.current)
    }
  }, [session])

  useEffect(() => {
    if (!session) {
      localStorage.removeItem(SESSION_KEY)
    } else {
      localStorage.setItem(SESSION_KEY, JSON.stringify(session))
    }
  }, [session])

  const graphqlClient = useMemo(() => {
    return getClient(session?.access_token)
  }, [session])

  return (
    <SessionContext.Provider value={{ setSession }}>
      <ApolloProvider client={graphqlClient}>{children}</ApolloProvider>
    </SessionContext.Provider>
  )
}

export default SessionProvider
