import React, { useEffect, useMemo, useState } from 'react'
import csvtojson from 'csvtojson'
import useICEscrow from '../../hooks/useICEscrow'

const Activity = ({ project }) => {
  const { escrow } = useICEscrow(project?.externalId)
  const [activity, setActivity] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!escrow) return
    setIsLoading(true)

    escrow
      .getAccountsInfo()
      .then((accountsInfo) => {
        return csvtojson().fromString(accountsInfo)
      })
      .then(setActivity)
      .finally(() => setIsLoading(false))
  }, [escrow])

  const fundedAccounts = useMemo(() => {
    return activity.filter(
      ({ subaccountStatus }) => subaccountStatus === 'funded',
    )
  }, [activity])

  return (
    <div className='w-full'>
      <div className='mx-auto w-full max-w-7xl'>
        <div className='relative overflow-x-auto rounded py-10'>
          <table className='w-full text-left text-sm text-gray-500'>
            <thead className='bg-gray-50 text-xs uppercase text-gray-700'>
              <tr>
                <th scope='col' className='px-6 py-3'>
                  Backer principal
                </th>
                <th scope='col' className='px-6 py-3'>
                  Tier
                </th>
                <th scope='col' className='px-6 py-3'>
                  At price
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className='border-b bg-white'>
                  <td
                    colSpan='3'
                    className='truncate whitespace-nowrap px-6 py-4 text-center'
                  >
                    Loading...
                  </td>
                </tr>
              ) : fundedAccounts.length === 0 ? (
                <tr className='border-b bg-white'>
                  <td
                    colSpan='3'
                    className='truncate whitespace-nowrap px-6 py-4 text-center'
                  >
                    No activity yet
                  </td>
                </tr>
              ) : (
                fundedAccounts.map(({ nftIndex, principal }) => {
                  return (
                    <tr key={principal} className='border-b bg-white'>
                      <td className='truncate whitespace-nowrap px-6 py-4'>
                        {principal}
                      </td>
                      <td className='truncate whitespace-nowrap px-6 py-4'>
                        {nftIndex}
                      </td>
                      <td className='truncate whitespace-nowrap px-6 py-4'>
                        {project?.tiers?.[nftIndex]?.price}
                      </td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Activity
