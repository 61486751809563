import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import useSession from '../../../hooks/useSession'
import useUser from '../../../hooks/useUser'

export default function End() {
  const { user } = useUser()
  const { setSession } = useSession()

  const logout = () => {
    setSession()
  }

  return (
    <>
      <Link to='/projects/new'>
        <button
          className={`
                ml-2 flex
                h-12 flex-row items-center space-x-1 rounded-full bg-gradient-to-br from-neutral-800 to-black
                px-8 text-sm font-medium
                text-white focus:outline-none
            `}
          type='button'
        >
          <span className='text-md'>
            Start <span className='hidden sm:inline'>a Crowdfund</span>
          </span>
        </button>
      </Link>
      {user && (
        <button
          className={`
                    ml-2 flex h-8 flex-row items-center
                    space-x-1 rounded-lg bg-white px-2 text-sm font-medium
                    text-blue-600 focus:outline-none 
                `}
          type='button'
          onClick={logout}
        >
          <span>Sign out</span>
        </button>
      )}
      {!user && (
        <Link to='/login'>
          <button
            className={`
                    ml-2 flex h-8 flex-row items-center
                    space-x-1 rounded-lg bg-white px-2 text-sm font-medium
                    text-blue-600 focus:outline-none 
                `}
            type='button'
            onClick={logout}
          >
            <span>Sign in</span>
          </button>
        </Link>
      )}
    </>
  )
}
